import React, { Component } from 'react'

export default class Loader extends Component {
    render() {
        return (
            <div className="ls-loader">
                <div className="logo-carrier">
                    <img src="logo/lysep_logo_raw.svg" alt="logo"/>
                    <img className="second" src="logo/lysep_logo_raw.svg" alt="logoanimated"/>
                    <h1>LYSEP</h1>
                </div>
            </div>
        )
    }
}
