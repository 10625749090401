import React, { Component } from 'react'
import './modal.scss'

export default class Modal extends Component {
    constructor(props){
        super(props)
    }
    render() {
        return (
            <div id={this.props.name} class="modal fade" tabindex="-1" role="dialog" aria-labelledby={this.props.name+"Label"} aria-hidden="true">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  ...
                </div>
              </div>
            </div>
        )
    }
}

