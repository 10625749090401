import React, { Component } from 'react'
function getFocused(s) {
    return s ? "focused" : ""
}
export default class SpaceColumn extends Component {
    render() {
        var cs = "space "+getFocused(this.props.focused);
        var pg = this.props.page ? this.props.page:""
        return (
            <div column={this.props.column} className={"ls-space-column "+pg}>
                <div className={cs}>
                    {this.props.children}
                </div>    
            </div>
        )
    }
}
