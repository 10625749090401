import React, { Component } from 'react'

export default class Returnback extends Component {
    componentDidMount(){
        document.querySelector(".returnback a").setAttribute("onclick","LoadPage(this);HideGoBack();")
    }
    render() {
        return (
            <div className="returnback left top fow-right unactive hide">
                <a page={this.props.page}>
                    <i className="fa"></i>
                    Home
                </a>
            </div>
        )
    }
}
