import React, { Component } from 'react'
import Loader from '../Components/loader'
import Returnback from '../Components/returnback'
import Space from '../Components/space'
import Vision from '../Components/vision'
import SpaceRow from '../Components/spacerow'
import SpaceColumn from '../Components/spacecolumn'
import Homepage from '../LysepMainPages/homepage'
import Discord from '../LysepMainPages/discord'
import Contacts from '../LysepMainPages/contacts'
import Apps from '../LysepMainPages/apps'
import CoreCheat from '../LysepMainPages/corecheat'
import './lysepmain.scss'

function appendScript(URL) {
    const script = document.createElement("script");
    script.src=URL;
    script.async=true;
    document.body.appendChild(script);
}
export default class LysepMain extends Component {
    componentDidMount(){
        appendScript("assets/js/jquery.min.js")
        appendScript("assets/js/bootstrap.min.js")
        appendScript("assets/js/lysep.js")
        appendScript("assets/js/fanimator.js")
    }
    render() {
        return (
            <div>
                <Space>
                    <Vision>
                        <SpaceRow row="0">
                            <SpaceColumn column="0" page="discordbot">
                                <Discord></Discord>
                            </SpaceColumn>
                            <SpaceColumn column="1" page="homepage" focused>
                                <Homepage></Homepage>
                            </SpaceColumn>
                            <SpaceColumn column="2" page="contacts">
                                <Contacts></Contacts>
                            </SpaceColumn>
                        </SpaceRow>
                        <SpaceRow row="1">
                            <SpaceColumn column="0">
                            </SpaceColumn>
                            <SpaceColumn column="1" page="apps">
                                <Apps></Apps>
                            </SpaceColumn>
                            <SpaceColumn column="2" page="corecheat">
                                <CoreCheat></CoreCheat>
                            </SpaceColumn>
                        </SpaceRow>
                        <SpaceRow row="2">
                            <SpaceColumn column="0">
                            </SpaceColumn>
                            <SpaceColumn column="1">
                            </SpaceColumn>
                            <SpaceColumn column="2">
                            </SpaceColumn>
                        </SpaceRow>
                    </Vision>
                </Space>
                <Loader></Loader>
                <Returnback page="homepage"></Returnback>
                
                
            </div>
        )
    }
}
// <ScriptTag type="text/javascript" src="assets/js/jquery.min.js"></ScriptTag>
                // <ScriptTag type="text/javascript" src="assets/js/bootstrap.min.js"></ScriptTag>
                // <ScriptTag type="text/javascript" src="assets/js/index.js"></ScriptTag>