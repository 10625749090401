import React, { Component } from 'react'
import "./poweredbylysep.scss";

export default class PoweredByLysep extends Component {
    render() {
        if(this.props.side){
            var side = this.props.side;

        }else{
            var side = "bottom-left";
        }
        return (
            <div className={"poweredbylysep "+side}>
                <div className="logo">
                    <img src="/logo/lysep_logo_raw.svg"></img>
                </div>
                <div className="innertext">{this.props.innerText}</div>
            </div>
        )
    }
}
