import React, { Component } from 'react'

export default class AppCard extends Component {
    componentDidMount(){
        document.querySelector(".card-content a.button").setAttribute("onclick","LoadPage(this)")
    }
    render() {
        if(this.props.data.name != "others"){
            return (
                <div appname={this.props.data.name} className="appcard col-sm-12 col-md-6 col-lg-4">
                    <div className="card-inner">
                        <div className="card-header" animationname={this.props.data.name}>
                            <div className="card-img">
                                <img src={this.props.data.logo} alt={this.props.data.name+" Image"}/>
                            </div>
                            <div className="card-brand">
                                <a className="brand">
                                    {this.props.data.display_name}
                                </a>
                            </div>
                        </div>
                        <div className="card-content">
                            <a className="desc">
                                {this.props.data.desc}
                            </a>
                            <a className="button" page={this.props.data.page_name}>View Project</a>
                        </div>
                    </div>
                </div>
            )
        }else{
            return (
                <div className="appcard col-sm-12 col-md-6 col-lg-4">
                    <div className="card-inner others">
                        <div className="cc-container">
                            <div className="center-carrier">
                                <h1>
                                    Check out our other <br/> projects
                                </h1>
                                <div className="centerDiv">
                                <a className="github" onClick={()=>{window.open("https://github.com/lysep-corp")}}>
                                    <i className="fa fa-github"></i>    
                                    Github
                                </a>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}
