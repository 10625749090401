import React, { Component } from 'react'

export default class ContactCard extends Component {
    render() {
        return (
            <div className="contact-profile">
                <div className={"card "+this.props.data.nickname.toLowerCase()}>
                    <div className="profile-photo">
                        <img src={this.props.data.github.profile_image} alt={this.props.data.nickname+" Profile photo"}/>
                    </div>
                    <div className="nickname">
                        <label htmlFor=".title">Nickname</label>
                        <a className="title">{this.props.data.nickname} </a>
                    </div>
                    {this.props.data.about!="!empty" &&
                        <div className="about">
                            <label htmlFor=".content">About</label>
                            <p className="content">{this.props.data.about}</p>
                        </div>
                    }
                    <div className="contacts">
                        <label htmlFor="contacts">Contacts</label>
                        <table>
                            <tbody>
                                <tr className="discord">
                                    <td><i className="fab fa-discord"></i></td> 
                                    <td>{this.props.data.contact.discord}</td> 
                                </tr>
                                <tr className="email">
                                    <td><i className="fas fa-envelope"></i></td> 
                                    <td><a href={"mailto:"+this.props.data.contact.mail}>{this.props.data.contact.mail}</a></td> 
                                </tr>
                                <tr className="github">
                                    <td><i className="fa fa-github"></i></td> 
                                    <td><a href={this.props.data.github.profile_link}>{this.props.data.nickname}</a></td> 
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}
