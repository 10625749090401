import React, { Component } from 'react'
import ContactCard from '../Components/contactcard'

export default class Contacts extends Component {
    constructor(props){
        super(props)
        this.state = {
            dataArray:[
            {
                id:0,
                nickname:"Septillioner",
                github:{
                    profile_image:"https://avatars2.githubusercontent.com/u/18403444?v=4",
                    profile_link:"https://github.com/Septillioner"
                },
                about:"!empty",
                contact:{
                    discord:"septillioner#4083",
                    mail:"septillioner@protonmail.com"
                }
            },
            {
                id:1,
                nickname:"Lyceion",
                github:{
                    profile_image:"https://avatars2.githubusercontent.com/u/21990827?v=4",
                    profile_link:"https://github.com/Lyceion"
                },
                about:"!empty",
                contact:{
                    discord:"Lyceion#2564",
                    mail:"lyceion@protonmail.com"
                }
            }
        ]  }
        this.list = this.state.dataArray.map(element=>{
            return (
             <ContactCard data={element} key={element.id}></ContactCard>
            );
        });
    }
    render() {
        return (
             <div className="inner-page-container">
                    <h1 className="pagebrand">
                        <a className="LYSEP">LYSEP</a>
                        Contacts
                    </h1>
                    <div className="contact-container">
                        <div className="contact-profiles">
                            {
                                this.list
                            }
                        </div> 
                    </div>
            </div>
        )
    }
}
