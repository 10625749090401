import React, { Component } from 'react'
import AppCard from '../Components/appcard';

export default class Apps extends Component {
    constructor(props){
        super(props)
        this.state = {
            dataArray:[
                {
                    id:0,
                    name:"corecheat",
                    page_name:"corecheat",
                    display_name:"CoreProject",
                    logo:"logo/CoreCheat.png",
                    theme_color:'#f50707',
                    desc:"God's Selection third party game helper software with C#",
                },
                {
                    id:1,
                    name:"others"
                }
                    // },
                // {
                //     id:1,
                //     name:"privedit",
                //     page_name:"privedit",
                //     display_name:"PrivEdit",
                //     logo:"logo/PrivEdit.png",
                //     theme_color:'#fc03c2',
                //     desc:"Simple modern text editor with C#",
                // }
            ]
        }
        this.app_list = this.state.dataArray.map(element=>{
            return (
                <AppCard data={element} key={element.id}></AppCard>
            );
        });
    }
    componentDidMount(){
        
    }
    render() {
        return (
            <div className="inner-page-container">
                <h1 className="pagebrand">
                    <a className="LYSEP">LYSEP</a> Apps
                </h1>
                <div className="container">
                    <div className="row">
                        {this.app_list}
                    </div>
                </div>
            </div>
        )
    }
}
