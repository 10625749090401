import React, { Component } from 'react'
import {DiscordLogo} from './discord';
import './homepage.scss'
import Modal from './../Components/modal'

export default class Homepage extends Component {
    componentDidMount(){
        document.querySelectorAll(".page-brands a.page-loader").forEach((e)=>{
            if(e.getAttribute("page")!=""){
                e.setAttribute("onclick","LoadPage(this);");
             }
        });
        //document.getElementById("gotogithub").setAttribute("onclick","window.open('https://github.com/lysep-corp');");
    }
    render() {
        return (
                <div className="center-container">
                    <div className="logo-carrier">
                        <img unselectable="on" className="logo" src="logo/lysep_logo_raw.svg" alt="LYSEP LOGO"/>
                        <h1 className="title"><a className="LYSEP">LYSEP</a>CORPORATION</h1>
                    </div>
                    <div className="logo-bottom-headers">
                        <div className="construction-brandheader">
                            <h1>Site under construction please come back later...</h1>
                        </div>
                        <div className="page-brands">
                            <ul>
                                <li><a className="page-loader" page="discordbot">
                                    <i className="fab fa-discord"></i>
                                    Discord Bot
                                </a></li>
                                <li><a className="page-loader" page="apps" >
                                    <i className="fa fa-th-large"></i>
                                    Apps</a></li>
                                <li><a className="page-loader" page="contacts">
                                    <i className="fa fa-phone"></i>
                                    Contacts</a></li>
                                <li><a className="donate" href="https://www.patreon.com/lysep">
                                    <i className="far fa-donate"></i>
                                    Donate</a>
                                    </li>
                            </ul>
                        </div>
                    </div>

                </div>
                )
    }
}
