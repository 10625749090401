import React, { Component } from 'react'
import "./corecheat.scss";
import PoweredByLysep from '../Components/poweredbylysep';
export default class CoreCheat extends Component {
    render() {
        return (
            <div className="inner-page-container">
                <div className="cr-header">
                    <div className="logo-border">
                        <img src="logo/CoreCheat.png"></img>
                    </div>
                    <div className="cr-brand">
                        <h1>
                            CoreProject
                        </h1>
                    </div>
                    <div className="cr-desc">
                        <div className="typeBox">
                            <div className="typeContainer">The God's Selection...</div>
                        </div>
                    </div>
                </div>
                <div className="cr-content">
                    <div className="youtube-video">
                        <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/eWSO749qlQI" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>
                    </div>
                    <div className="buttons">
                        <div className="button-container">
                            <a className="download" href="Releases/CoreCheat.zip">
                                <i className="fa fa-download"></i>    
                                Download
                            </a>
                            <a className="github" onClick={()=>{window.open("https://github.com/lysep-corp/CoreCheatCSGO-LSREMAKE")}}>
                                <i className="fa fa-github"></i>    
                                Github
                            </a>
                        </div>
                    </div>
                    <div className="shakey">
                    SHA-256: 3e98c266952c6a5758c6d9ff54834851174fb549a07feecde81ba16646ad81e7
                    </div>
                </div>
            </div>
        )
    }
}
