import React, { Component } from 'react'

export default class SpaceRow extends Component {
    render() {
        return (
            <div className="ls-space-row" row={this.props.row}>
                {this.props.children}
            </div>
        )
    }
}
